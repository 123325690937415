import {
    Box,
    Button,
    Chip,
    CircularProgress,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import * as alarmeService from "../services/alarmeService";
import * as userService from "../services/userService";
import { HeaderTitle } from "../styles/UsuariosStyle";
import { getUserId } from "../services/authService";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/system";
import { useNavigate } from "react-router-dom";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as moment from "moment";
import { SelectUsina } from "../components/SelectUsina";
import { useAuth } from "../hooks/Auth";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#0a5068",
        color: "#fff",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#e9ecef",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
                style={{ marginTop: -15 }}
            >
                {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
                style={{ marginTop: -15 }}
            >
                {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
                style={{ marginTop: -15 }}
            >
                {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
                style={{ marginTop: -15, marginRight: 8 }}
            >
                {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

const defaultFilters = {
    nome: "",
    usina: "",
    tipo_equipamento: "",
    status: "",
    data_inicio: null,
    data_fim: null,
};

export default function VisualizarAlarmes() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [alarmes, setAlarmes] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filters, setFilters] = useState(defaultFilters);
    const [total, setTotal] = useState(0);
    const { enqueueSnackbar } = useSnackbar();

    const { setNotificationsCount, getUserPerfil } = useAuth();
    const userPerfil = getUserPerfil();

    useEffect(async () => {
        const response = await userService.getOneUser(localStorage.getItem('@Toth-userId'));
        defaultFilters.usina = response.usinasUsuario[0].usina.id
        setFilters(defaultFilters)
        getAlarmes();
        updateDataVisualizacaoAlarmes();
        setNotificationsCount(0);

        let counter = 0;
        dispatch({ type: "SET_COUNTER", value: counter });

    }, []);

    useEffect(async () => {
        getAlarmes();
    }, [page]);

    const updateDataVisualizacaoAlarmes = async () => {
        try {
            await userService.editSelfUser({
                data_visualizacao_alarmes: moment().toDate(),
            });
        } catch (error) {
            console.error("error updateDataVisualizacaoAlarmes: ", error);
        }
    };
    const getAlarmes = async (limpar = false) => {
        try {
            setLoading(true);
            const { total, alarmes } = await alarmeService.getAllAlarms({
                filters: limpar ? defaultFilters : filters,
                currentPage: page,
            });
            setAlarmes(alarmes);
            setTotal(total);
            setLoading(false);
        } catch (error) {
            enqueueSnackbar("Ocorreu um erro ao buscar os usuários!", { variant: "error" });
            setLoading(false);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <Paper style={{ minHeight: " 90vh", padding: "38px" }} elevation={0}>
                <>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            mb: 8,
                        }}
                    >
                        <HeaderTitle>Alarmes</HeaderTitle>
                        {userPerfil !== "RESPONSAVEL_USINA" && (
                            <Button
                                variant="contained"
                                onClick={() => {
                                    navigate("/controle_alarmes");
                                }}
                                style={{ backgroundColor: "#0a5068" }}
                            >
                                Configurar alarmes
                            </Button>
                        )}
                    </Box>

                    <>
                        <Box sx={{ mb: 3, mt: 6 }}>
                            <HeaderTitle style={{ fontSize: 16 }}>Filtros</HeaderTitle>
                        </Box>

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                mb: 4,
                            }}
                        >
                            <SelectUsina
                                value={filters.usina}
                                onChange={(e) => setFilters({ ...filters, usina: e.target.value })}
                            />
                            <FormControl style={{ width: "17%" }}>
                                <InputLabel>Tipo do equipamento</InputLabel>
                                <Select
                                    value={filters.tipo_equipamento}
                                    onChange={(e) =>
                                        setFilters({ ...filters, tipo_equipamento: e.target.value })
                                    }
                                    label="Tipo do equipamento"
                                >
                                    <MenuItem value={""}>
                                        <em>Selecione</em>
                                    </MenuItem>
                                    <MenuItem value={"COMBINER"}>Combiner</MenuItem>
                                    <MenuItem value={"INVERSOR"}>Inversor</MenuItem>
                                    <MenuItem value={"TRACKER"}>Tracker</MenuItem>
                                    <MenuItem value={"RELE"}>Rele</MenuItem>
                                    <MenuItem value={"EnergyTransformer"}>Transformador</MenuItem>
                                    <MenuItem value={"USINA"}>Usina</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl style={{ width: "17%" }}>
                                <InputLabel>Status</InputLabel>
                                <Select
                                    value={filters.status}
                                    onChange={(e) =>
                                        setFilters({ ...filters, status: e.target.value })
                                    }
                                    label="Status"
                                >
                                    <MenuItem value={""}>
                                        <em>Selecione</em>
                                    </MenuItem>
                                    <MenuItem value={"ativo"}>Ativo</MenuItem>
                                    <MenuItem value={"inativo"}>Inativo</MenuItem>
                                </Select>
                            </FormControl>

                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DateTimePicker
                                    renderInput={(params) => <TextField {...params} />}
                                    label="Data de início"
                                    value={filters.data_inicio}
                                    inputFormat="DD/MM/YYYY HH:mm:ss"
                                    onChange={(newValue) => {
                                        setFilters({
                                            ...filters,
                                            data_inicio: moment(newValue).toDate(),
                                        });
                                    }}
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DateTimePicker
                                    renderInput={(params) => <TextField {...params} />}
                                    label="Data fim"
                                    value={filters.data_fim}
                                    inputFormat="DD/MM/YYYY HH:mm:ss"
                                    onChange={(newValue) => {
                                        setFilters({
                                            ...filters,
                                            data_fim: moment(newValue).toDate(),
                                        });
                                    }}
                                />
                            </LocalizationProvider>

                            <Button
                                variant="outlined"
                                onClick={() => {
                                    setFilters({ ...defaultFilters, usina: filters.usina });
                                    getAlarmes(true);
                                }}
                                style={{ color: "#0a5068", borderColor: "#0a5068" }}
                            >
                                Limpar
                            </Button>

                            <Button
                                onClick={() => {
                                    getAlarmes();
                                }}
                                size="large"
                                style={{ backgroundColor: "#0a5068", color: "white" }}
                            >
                                Buscar
                            </Button>
                        </Box>

                        {!loading ? (
                            <>
                                <TableContainer
                                    sx={{ maxHeight: 460, marginTop: 3 }}
                                    component={Paper}
                                >
                                    <Table
                                        stickyHeader
                                        sx={{ minWidth: 460 }}
                                        aria-label="customized table"
                                    >
                                        <TableHead>
                                            <TableRow style={{ height: 1 }}>
                                                <StyledTableCell>Descrição</StyledTableCell>
                                                <StyledTableCell>Equipamento</StyledTableCell>
                                                <StyledTableCell>Usina</StyledTableCell>
                                                <StyledTableCell>Status</StyledTableCell>
                                                <StyledTableCell>Data início</StyledTableCell>
                                                <StyledTableCell>Data fim</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {alarmes.map((alarme) => (
                                                <StyledTableRow key={alarme.id}>
                                                    <StyledTableCell component="th" scope="row">
                                                        {alarme.descricao}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {alarme.equipamento}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {alarme.usina}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {alarme.status == "Ativo" ? (
                                                            <Chip
                                                                label="Ativo"
                                                                variant="outlined"
                                                                color="error"
                                                            />
                                                        ) : (
                                                            <Chip
                                                                label="Inativo"
                                                                variant="outlined"
                                                                color="success"
                                                            />
                                                        )}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {alarme.data_inicio}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {alarme.data_fim}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <TableContainer
                                    style={{
                                        marginTop: 16,
                                        marginBottom: 0,
                                        borderBottom: 0,
                                        minHeight: 0,
                                    }}
                                >
                                    <Table stickyHeader aria-label="customized table">
                                        <TableFooter>
                                            <TablePagination
                                                rowsPerPageOptions={[]}
                                                count={total}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: {
                                                        "aria-label": "Linhas por página",
                                                    },
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </>
                        ) : (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "50vh",
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        )}
                    </>
                </>
            </Paper>
        </>
    );
}
