// URL BACKEND DOS AMBIENTES
export const BACKEND_LOCAL = "https://api.mv-toth.com";

// limite do número de resultados exibidos nos gráficos
export const CHART_LIMIT = 75;

// locale para ser usado no componente DatePicker
export const CUSTOM_LOCALE = {
    // months list by order
    months: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
    ],

    // week days by order
    weekDays: [
        {
            name: 'Domingo',
            short: 'Dom',
            isWeekend: true,
        },
        {
            name: 'Segunda-feira',
            short: 'Seg',
        },
        {
            name: 'Terça-feira',
            short: 'Ter',
        },
        {
            name: 'Quarta-feira',
            short: 'Qua',
        },
        {
            name: 'Quinta-feira',
            short: 'Qui',
        },
        {
            name: 'Sexta-feira',
            short: 'Sex',
        },
        {
            name: 'Sábado',
            short: 'Sab',
            isWeekend: true,
        },
    ],

    // just play around with this number between 0 and 6
    weekStartingIndex: 0,

    // return a { year: number, month: number, day: number } object
    getToday(gregorainTodayObject) {
        return gregorainTodayObject;
    },

    // return a native JavaScript date here
    toNativeDate(date) {
        return new Date(date.year, date.month - 1, date.day);
    },

    // return a number for date's month length
    getMonthLength(date) {
        return new Date(date.year, date.month, 0).getDate();
    },

    // return a transformed digit to your locale
    transformDigit(digit) {
        return digit;
    },

    // texts in the date picker
    nextMonth: 'Próximo Mês',
    previousMonth: 'Mês Anterior',
    openMonthSelector: 'Abrir Seletor de Mês',
    openYearSelector: 'Abrir Seletor de Ano',
    closeMonthSelector: 'Fechar Seletor de Mês',
    closeYearSelector: 'Fechar Seletor de Ano',
    defaultPlaceholder: 'Selecionar...',

    // for input range value
    from: 'De',
    to: 'Até',


    // used for input value when multi dates are selected
    digitSeparator: ',',

    // if your provide -2 for example, year will be 2 digited
    yearLetterSkip: 0,

    // is your language rtl or ltr?
    isRtl: false,
};

export const roles = {
    ADMIN: "ADMIN",
    PROPRIETARIO: "PROPRIETARIO_USINA",
    RESPONSAVEL: "RESPONSAVEL_USINA",
};
